.umbuchen-showcase {
  padding-top: 0.5rem;
  padding-bottom: 2rem;
  .row {
    background-color: #ffffff;
    max-width: 1100px;
    margin: auto;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
      0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
  }
  .image_container {
    padding: 0;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .main_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.6rem;
  }
  .termin_container {
    padding-left: 4px;
  }
  .termin {
    font-size: 2rem;
    font-weight: 600;
  }
  .hour {
    font-size: 1.2rem;
  }

  .second-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2rem;
  }
  .left-part {
    font-size: 1.1rem;
  }

  .platz_container {
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .platz {
    display: block;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    padding: 0.5rem 1.5rem;
    border-radius: 12px;
    background-color: $green-color;
  }
  .wenig {
    background-color: $orange-color;
    align-self: end;
    justify-self: end;
  }
  .booked {
    background-color: $gray-color;
  }

  .check_container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .warteliste-right-part {
    align-self: flex-end;
    @media (max-width: 575.98px) {
      align-self: center;
    }
  }
  .preis {
    font-size: 1.5rem;
    font-weight: 600;
    margin-right: 1rem;
  }
  .btn {
    background-color: $third-color;
    &:hover {
      background-color: lighten($third-color, 10%);
    }
  }
  .kurs_leitung {
    font-weight: 600;
  }
  @media (max-width: 991.98px) {
    .main_content {
      flex-direction: column;
      justify-content: center;
    }
    .hour {
      text-align: center;
    }
  }
  @media (max-width: 767.98px) {
    .image_container {
      display: none;
    }
  }

  @media (max-width: 575.98px) {
    .termin,
    .hour {
      text-align: center;
    }

    .second-content {
      flex-direction: column;
    }
    .left-part {
      padding-bottom: 2rem;
    }

    .platz_container {
      justify-content: center;
    }
  }
  @media (max-width: 400.98px) {
    .check_container {
      flex-direction: column;
    }
    .preis {
      padding-bottom: 0.6rem;
    }
  }
}
