.law {
  h1 {
    padding-top: 2rem;
  }
  br {
    content: ' ';
    display: block;
    margin: 1em;
  }
  .z-a-part {
    padding: 2rem 0rem;
    .z-title {
      font-size: 1.4rem;
      font-weight: 600;
    }
  }
  .z-sub-title {
    padding-top: 1rem;
    font-weight: 600;
  }
  ul.z-circle {
    list-style: circle;
    padding-left: 2rem;
    li {
      padding-bottom: 0.5rem;
    }
  }
}

@media (max-width: 575.98px) {
  .law {
    h1.z-h1-media {
      font-size: 1.5rem;
    }
  }
}
