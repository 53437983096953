@import 'allfiles';
@import '../components/main.scss'; // ###############################################

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-family;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #ffffff;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.preload * {
  animation-duration: 0s !important;
  -webkit-animation-duration: 0s !important;
  transition: background-color 0s, opacity 0s, color 0s, width 0s, height 0s,
    padding 0s, margin 0s !important;
}
a {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  &:hover {
    text-decoration: none;
    color: $third-color;
  }
}

.test-space {
  min-height: 100vh;
}
ul {
  list-style: none;
}
// sticky footer

.mobile-overflow-wrapper {
  overflow-x: hidden;
}
.dark-background {
  background-color: $dark-color;
}

.main-container-admin {
  min-height: 100vh;
}
