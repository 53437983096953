.accordion-wrapper {
  & + * {
    margin-top: 1em;
  }
  .accordion-item {
    overflow: hidden;
    transition: max-height 0.8s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
  }

  .accordion-item.collapsed {
    max-height: 0;
    transition: max-height 0.8s cubic-bezier(0, 1, 0, 1);
  }

  .accordion-title {
    font-weight: 600;
    cursor: pointer;
    color: #666;
    padding: 0.5em 1.5em;
    border: solid 1px #ccc;
    border-radius: 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.3rem;
    transition: $transition;
    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid currentColor;
    }

    &:hover,
    &.open {
      color: black;
    }

    &.open {
      &::after {
        content: '';
        border-top: 0;
        border-bottom: 5px solid;
      }
    }
  }

  .accordion-content {
    padding: 1em 1.5em;
  }
  .img-container.paypal {
    text-align: center;
    min-height: 60px;
    img {
      width: 150px;
      height: auto;
      cursor: pointer;
      transition: $transition;
      &:hover {
        width: 160px;
      }
    }
  }
  .img-container.sepa {
    text-align: center;
    min-height: 65px;
    img {
      width: 150px;
      height: auto;
      cursor: pointer;
      transition: $transition;
      &:hover {
        width: 160px;
      }
    }
  }

  .img-container.klarna {
    text-align: center;
    min-height: 80px;
    img {
      width: 100px;
      height: auto;
      cursor: pointer;
      transition: $transition;
      &:hover {
        width: 104px;
      }
    }
  }
  .img-container.creditcard {
    text-align: center;
    min-height: 90px;
    img {
      width: 160px;
      height: auto;
      cursor: pointer;
      transition: $transition;
      &:hover {
        width: 170px;
      }
    }
  }
  .vorkasse {
    font-size: 1.2rem;
    @media (max-width: 767.98px) {
      table,
      thead,
      tbody,
      th,
      td,
      tr {
        display: block;
        font-size: 1rem;
      }
      tr {
        padding-bottom: 1rem;
      }
    }
  }
}
