.umbuchen-admin {
  background-color: $secondary-color;
  padding-bottom: 4rem;
  min-height: 100vh;
  .title {
    text-align: center;
    font-size: 2.2rem;
    color: #000000;
    font-weight: 600;
  }
  .desc {
    max-width: 900px;
    text-align: center;
    margin: auto;
    font-size: 1.2rem;
    color: #000000;
  }
  .sub-title {
    font-size: 1.8rem;
    color: #000000;
    font-weight: 600;
  }
}
