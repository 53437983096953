.form-control:focus {
  border-color: $third-color;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $third-color;
}
form small {
  display: none;
  padding: 0.4rem;
}

form small.z-green {
  display: block;
  width: 100%;
  color: $green-color;
}

form small.red,
form small.error {
  display: block;
  width: 100%;
  color: $red-color;
  padding: 0rem;
}
form small.z-black {
  display: block;
  width: 100%;
  color: #333;
}

form input.red,
form input.error {
  border: solid 1px $red-color !important;
}

form p.error-top {
  color: $red-color;
  padding-bottom: 1rem;
  font-weight: 600;
}
