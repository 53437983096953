.message {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 1rem;
  transform: translate(0%, -100%);
  transition: all 0.8s ease-in-out;
  cursor: pointer;
  z-index: 10000;
  &.active {
    transform: translate(0%, 0%);
  }
  .content {
    width: 90%;
    max-width: 600px;
    margin: auto;
    font-size: 1.2rem;
    background-color: #fff;
    border-radius: 12px;
    padding: 1rem;
    padding-bottom: 2rem;
    -webkit-box-shadow: 3px 3px 5px 6px #ccc;
    -moz-box-shadow: 3px 3px 5px 6px #ccc;
    box-shadow: 3px 3px 5px 6px #ccc;
    border: 1px solid $primary-color;
    color: #000000;
    cursor: default;
  }

  .close {
    font-weight: 600;
    float: right;
    text-align: right;
    color: #333;
    cursor: pointer;
    &:hover {
      color: $third-color;
    }
  }
  .btn_container {
    display: flex;
    padding-top: 1rem;
    align-items: center;
    justify-content: center;
  }

  span.red-text {
    color: $red-color !important;
    font-weight: 600;
  }
}

@media (max-width: 450.98px) {
  .message {
    .content {
      font-size: 1rem;
    }
  }
}
