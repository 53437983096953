.website-info-kasse {
  background-color: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
  padding: 1rem 5%;
  border-radius: 4px;
  &.freeze {
    pointer-events: none;
    #purchaseBtn {
      display: none;
    }
  }
  .error-top {
    color: $red-color;
    font-size: 1rem;
    display: none;
    &.error {
      display: block;
    }
  }
  .title {
    font-size: 1.6rem;
    font-weight: 600;
    padding-bottom: 1rem;
  }
  .input-container {
    width: 100%;
    transition: $transition;
    margin-bottom: 1.3rem;
    p {
      margin-bottom: 0.4rem;
      font-size: 1.1rem;
    }
    input {
      width: 100%;
    }
    .birthday-input {
      max-width: 120px;
    }
    small {
      opacity: 0;
      color: $red-color;
      font-weight: 600;
    }
    &.error {
      input {
        border: 0.5px solid $red-color;
      }
      small {
        opacity: 1;
      }
    }
  }
}
