.website-bezahlung {
  padding-bottom: 4rem;
  min-height: 100vh;
  background-color: $secondary-color;
  h2 {
    font-size: 1.9rem;
  }
  .second-part {
    background-color: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
      0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
    padding: 1rem 5%;
    border-radius: 4px;
  }
  .zahluebergang {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 800px;
    margin: auto;
    padding-bottom: 2rem;
  }
  .betrag {
    font-size: 1.4rem;
    font-weight: 600;
  }
  .sub-title {
    font-size: 1.9rem;
    font-weight: 600;
    padding-bottom: 0.4rem;
  }
  .accordion-container {
    max-width: 1200px;
    margin: auto;
    padding-bottom: 5rem;
  }
  .desc-bezahlung {
    h2 {
      text-align: center;
      padding-bottom: 0.4rem;
    }
    p {
      font-size: 1.1rem;
      padding-bottom: 0.75rem;
    }
  }
}
