.transition-box {
  background-color: $primary-color;
  .row {
    min-height: 60px;
  }
  a {
    color: #fff;
    font-size: 1.6rem;
  }
}

hr {
  width: 100%;
  color: #333;
  height: 2px;
  background-color: $secondary-color;
}

br.z-br {
  content: ' ';
  display: block;
  margin: 1em;
}

.z-bold {
  font-weight: 600;
}

.side-mail {
  position: fixed;
  top: 50%;
  right: 0%;
  padding: 0.4rem 0.8rem;
  background-color: $third-color;
  border-radius: 25px 0 0 25px;
  transition: all 0.5s ease;
  a {
    color: #fff;
    font-size: 1.5rem;
  }
  &:hover {
    padding-right: 33px;
  }
  span {
    visibility: hidden;
  }
}
@media (max-width: 575.98px) {
  .transition-box div {
    a {
      font-size: 1rem;
    }
  }
}

// buttons
#back-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  padding: 0.6rem 0.8rem;
  background-color: $third-color;
  border-radius: 20%;

  a {
    color: #fff;
    &:hover {
      color: #333;
    }
  }
}

// for front-end js
#kontakt-form {
  small {
    display: none;
  }
  .error {
    padding-bottom: 20px;
    position: relative;
    small {
      display: block;
      color: $red-color;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    input {
      border: 1px solid $red-color;
    }
  }
}

//PopUp Modal from W3School
/* The Modal (background) */
.z-modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  /* Modal Content/Box */
  .z-modal-content {
    background-color: $light-color;
    margin: 26% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid $primary-color;
    width: 80%; /* Could be more or less, depending on screen size */
    max-width: 600px;
  }
  /* The Close Button */
  .z-close-btn {
    color: #333;
    font-size: 24px;
    font-weight: bold;
    &:hover,
    &:focus {
      color: $red-color;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.box-shadow {
  -webkit-box-shadow: 3px 3px 5px 6px #ccc; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 3px 3px 5px 6px #ccc; /* Firefox 3.5 - 3.6 */
  box-shadow: 3px 3px 5px 6px #ccc; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}
