$loader-transition: all 0.2s ease-in;
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.6s ease-in;
  min-height: 100vh;
  height: 100%;
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -10;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $loader-transition;
  &.active {
    z-index: 10;
    opacity: 0.8;
    pointer-events: none;
    .loader,
    .circle {
      opacity: 1;
    }
  }
  .loader {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    opacity: 0;
    transition: $loader-transition;
  }
  .circle {
    position: absolute;
    width: 0px;
    height: 0px;
    border-radius: 100%;
    background: $primary-color;
    animation: radar 3s ease-out infinite;
    box-shadow: 0px 0px 10px darken($primary-color, 10%);
    opacity: 0;
    transition: $loader-transition;
  }

  .circle:nth-of-type(1) {
    animation-delay: 0.2s;
  }

  .circle:nth-of-type(2) {
    animation-delay: 0.6s;
  }

  .circle:nth-of-type(3) {
    animation-delay: 1s;
  }

  .circle:nth-of-type(4) {
    animation-delay: 1.4s;
  }

  .circle:nth-of-type(5) {
    animation-delay: 1.8s;
  }

  @keyframes radar {
    0% {
    }
    30% {
      width: 50px;
      height: 50px;
    }
    100% {
      width: 50px;
      height: 50px;
      opacity: 0;
    }
  }
}
