button {
  padding: 0.6rem 1.2rem;
  background-color: $button-color;
  text-transform: uppercase;
  color: #fff;
  transition: $transition;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: darken($button-color, 10%);
  }
  &:focus {
    outline: none;
  }
  &:active {
    transform: scale(0.98);
  }
  &.inactive {
    pointer-events: none;
  }
}

button.transparent {
  color: #333;
  background-color: #fff;
  &:hover {
    color: $third-color;
  }
}
button.btn-3 {
  color: #fff;
  background-color: $third-color;
  &:hover {
    background-color: darken($third-color, 10%);
  }
}

button.btn-green {
  color: #fff;
  background-color: $green-color;
  &:hover {
    background-color: darken($green-color, 10%);
  }
}

button.btn-blue {
  color: #fff;
  background-color: $blue-color;
  &:hover {
    background-color: darken($blue-color, 10%);
  }
}

button.btn-red {
  color: #fff;
  background-color: $red-color;
  &:hover {
    background-color: darken($red-color, 10%);
  }
}
button.btn-100 {
  width: 100%;
}

a.btn {
  padding: 0.6rem 1.2rem;
  background-color: $button-color;
  text-transform: uppercase;
  color: #fff;
  transition: $transition;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: darken($button-color, 10%);
    color: #fff;
  }
}

a.btn-transparent {
  padding: 0.6rem 1.2rem;
  text-transform: uppercase;
  color: #000000;
  transition: $transition;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    color: $primary-color;
  }
}
a.btn-3 {
  padding: 0.6rem 1.2rem;
  background-color: $third-color;
  text-transform: uppercase;
  color: #fff;
  transition: $transition;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: darken($third-color, 10%);
    color: #fff;
  }
}

.btn-container-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    margin: 0 0.25rem;
  }

  @media (max-width: 575.98px) {
    flex-direction: column;
    button {
      margin-bottom: 0.75rem;
    }
  }
}
