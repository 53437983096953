// Footer
footer.website-footer {
  background-color: $dark-color;
  p.footer-btn {
    color: #fff;
    transition: $transition;
    cursor: pointer;
    &:hover {
      color: $primary-color;
      text-decoration: none;
    }
  }
  p {
    color: $primary-color;
  }

  ul {
    li {
      padding-bottom: 0.2rem;
      color: #fff;
    }
  }
  .copyright {
    color: $primary-color;
    font-size: 1.2rem;
    font-weight: 600;
  }
}
