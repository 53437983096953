.pdf-viewer {
  display: flex;
  align-items: center;
  justify-content: center;
  &-content {
    // position: relative;
    // width: auto;
    // height: auto;
    // height: 750px;
    // max-width: 900px;
    // max-height: 95%;
    padding: 4px;
    // object-fit: cover;
    background-color: black;
    border: 5px solid white;
  }
  @media (max-width: 900.98px) {
    &-content {
      max-width: 95%;
    }
  }
}
