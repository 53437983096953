.partner-insgesamt {
  text-align: center;
  font-size: 1.2rem;

  span {
    color: $primary-color;
    font-weight: 600;
    margin-right: 8px;
  }
  .title {
    font-size: 1.4rem;
    color: #333;
  }
}
