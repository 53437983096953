.suche {
  padding-top: 2rem;
  padding-bottom: 6rem;
  min-height: 600px;
  .title {
    font-size: 2rem;
    color: $primary-color;
    text-align: center;
    padding-bottom: 1rem;
  }
  .input-container {
    width: 600px;
    margin: auto;
    max-width: 100%;
  }
}
