.gutschein-admin {
  padding: 1rem 0rem;
  background-color: $light-color;
  padding-bottom: 300px;
  min-height: 100vh;
  .gutschein-container {
    width: 100%;
    max-width: 800px;
    margin: auto;
    .error-text {
      color: $red-color;
      font-size: 1.2rem;
      font-weight: 600;
      transition: $transition;
      .hide {
        opacity: 0;
      }
    }
  }
  .spinner {
    font-size: 1.2rem;
    margin-left: 0.4rem;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      color: $primary-color;
    }
  }
  .statistik-container {
    font-size: 1.4rem;

    span {
      color: $primary-color;
    }
    p {
      margin-right: 0.4rem;
    }
  }
  input.error {
    border-color: $red-color;
  }
  .error-bottom {
    padding-top: 0.5rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: $red-color;
    opacity: 0;
    transition: $transition;
    &.error {
      opacity: 1;
    }
  }
  h2 {
    text-align: center;
    font-size: 2.5rem;
  }
  .input-container {
    p {
      font-size: 1.6rem;
    }
    input {
      width: 100%;
      max-width: 200px;
    }
  }

  .btn_container {
    text-align: center;
    padding: 2rem 0rem;
    @media (max-width: 500.98px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      & > * {
        margin-bottom: 0.5rem;
      }
    }
  }
  .time-container {
    max-width: 500px;
    input {
      width: 120px;
    }
    span {
      text-align: center;
    }
    @media (max-width: 575.98px) {
      // text-align: center;
      .col-sm-6 {
        padding-bottom: 1rem;
      }
    }
  }

  .tn-statistik {
    font-size: 1rem !important;
    color: $primary-color;
    padding-bottom: 0.3rem;
    margin: 0 !important;
  }
}

.gutschein {
  .statistik-container {
    font-size: 1.4rem;

    span {
      color: $primary-color;
    }
    p {
      margin-right: 0.4rem;
    }
  }
}
