.admin {
  max-width: 800px;
  margin: auto;
  h1 {
    font-size: 2.2rem;
  }
  .one-admin {
    .title {
      font-size: 1.4rem;
    }
  }
  .drop-container {
    p {
      color: $primary-color;
      font-size: 1.2rem;
    }
  }
}
