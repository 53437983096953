.captcha-gutschein {
  .sub-title {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .input-container {
    input {
      max-width: 200px;
    }
  }

  .captcha_img_container {
    padding: 0.4rem 0rem;
    img {
      display: block;
      object-fit: contain;
      height: auto;
      width: 250px;
    }
  }
  .captcha-container {
    small {
      opacity: 0;
      color: $red-color;
      font-weight: 600;
      font-size: 1rem;
    }
    &.error {
      input {
        border: 0.5px solid $red-color;
      }
      small {
        opacity: 1;
      }
    }
  }
  .captcha-input-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .spinner {
      font-size: 1.2rem;
      margin-left: 0.8rem;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        color: $primary-color;
      }
    }
  }
  .law-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    a {
      color: $third-color;
    }

    small {
      opacity: 0;
      transition: all 0.25s ease-out;
    }
    &.error small {
      opacity: 1;
      color: $red-color;
    }
  }
}
