.box-from-top {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 1rem;
  transform: translate(0%, -100%);
  transition: all 0.8s ease-in-out;
  cursor: pointer;
  z-index: 12;
  &.active {
    transform: translate(0%, 0%);
  }
  .content {
    width: 90%;
    max-width: 600px;
    margin: auto;
    border: 2px solid $secondary-color;
    background-color: #fff;
    border-radius: 12px;
    padding: 1rem;
    padding-bottom: 2rem;
    -webkit-box-shadow: 3px 3px 5px 6px #ccc;
    -moz-box-shadow: 3px 3px 5px 6px #ccc;
    box-shadow: 3px 3px 5px 6px #ccc;
    color: #333;
    cursor: default;
  }

  .close {
    font-weight: 600;
    float: right;
    text-align: right;
    color: #333;
    cursor: pointer;
    &:hover {
      color: $third-color;
    }
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  transition: 200ms ease-in-out;
  min-height: 100vh;
  height: 100%;
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -10;
  opacity: 0;
  &.active {
    z-index: 10;
    opacity: 0.9;
    pointer-events: none;
  }
}

@media (max-width: 450.98px) {
  .box-from-top {
    font-size: 0.8rem;
  }
}
