.website-erfolg {
  padding-bottom: 4rem;
  min-height: 100vh;
  background-color: $secondary-color;
  h2 {
    font-size: 1.9rem;
  }
  .website-erfolg-text {
    background-color: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
      0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
    padding: 1rem 5%;
    border-radius: 4px;
    .extra_text {
      max-width: 600px;
      margin: auto;
      font-size: 1.2rem;
      a {
        font-size: 1.2rem;
        color: #fff;
      }
      .goodbye {
        text-align: center;
        padding-top: 2rem;
        font-weight: 600;
        font-size: 2rem;
        text-transform: uppercase;
      }
      ul {
        padding: 2rem 0rem;
        li {
          padding-bottom: 0.4rem;
          i {
            padding-right: 0.2rem;
            color: $primary-color;
          }
          @media (max-width: 575.98px) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding-bottom: 1rem;
            i {
              padding-right: 0rem;
              color: $primary-color;
            }
          }
        }
      }
    }
  }
}
