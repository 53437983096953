.z-nav {
  background-color: $primary-color;
  display: flex;
  justify-content: space-around;
  height: 100px;
  padding: 1rem 0rem;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
  // margin-bottom: 4px;
  position: relative;
  .z-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: #ffffff;
    font-weight: 600;
    img {
      max-width: 300px;
      height: auto;
      object-fit: cover;
      cursor: pointer;
    }
  }
  .z-nav-pages {
    display: flex;
    align-items: center;
    .z-nav-page {
      padding-right: 1.5rem;
      a {
        font-size: 1.3rem;
        color: #ffffff;
        transition: $transition;
        &:hover {
          color: #333;
        }
      }
    }
  }
  .z-burger {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: $transition;
    div {
      width: 25px;
      height: 3px;
      background-color: #ffffff;
      margin: 2.5px;
      transition: all 0.3s ease;
    }
    &:hover {
      div {
        background-color: #333 !important;
      }
    }
  }
}
@media (max-width: 991.98px) {
  .mobile-overflow-wrapper {
    overflow-x: hidden;
  }
  .z-nav {
    .z-nav-pages {
      background-color: $secondary-color;
      opacity: 0.9;
      display: flex;
      position: absolute;
      right: 0px;
      min-width: 300px;
      max-width: 800px;
      top: 100px;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      transform: translateX(100%);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
        0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
      z-index: 1000;
    }
    .z-nav-page {
      opacity: 0;
      padding: 1.2rem 0rem;
      padding-right: 0rem !important;
      a {
        color: #333 !important;
        &:hover {
          color: $primary-color !important;
        }
      }
    }
    .z-burger {
      display: flex;
    }
  }
}

@media (max-width: 400.98px) {
  .z-nav {
    .z-logo-container {
      img {
        max-width: 160px;
        height: auto;
        object-fit: cover;
      }
    }
  }
}
@keyframes navSlideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes navSlideOut {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.z-toggle {
  div {
    background-color: #333 !important;
  }
  .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  .line2 {
    opacity: 0;
  }
  .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}
