#MessageWithLogo {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 1rem;
  transform: translate(0%, -100%);
  transition: all 0.8s ease-in-out;
  cursor: pointer;
  z-index: 12;
  &.active {
    transform: translate(0%, 0%);
  }

  .content {
    width: 90%;
    max-width: 600px;
    margin: auto;
    font-size: 1.2rem;
    border: 2px solid $secondary-color;
    background-color: #fff;
    border-radius: 12px;
    padding: 1rem;
    padding-bottom: 2rem;
    -webkit-box-shadow: 3px 3px 5px 6px #ccc;
    -moz-box-shadow: 3px 3px 5px 6px #ccc;
    box-shadow: 3px 3px 5px 6px #ccc;
    color: #333;
    cursor: default;
  }
  .img-container {
    width: 100%;
    text-align: center;
  }
  img {
    height: 120px;
    width: auto;
  }
  .btn-container {
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      text-transform: uppercase;
    }
  }

  .close {
    font-weight: 600;
    float: right;
    text-align: right;
    color: #333;
    cursor: pointer;
    transition: all 0.2s ease-in;
    &:hover {
      color: $primary-color;
    }
  }
}

@media (max-width: 530.98px) {
  #MessageWithLogo {
    .btn-container {
      padding-top: 1rem;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        margin-bottom: 0.5rem;
      }
    }
  }
}
@media (max-width: 450.98px) {
  #MessageWithLogo {
    .content {
      font-size: 1rem;
    }
    .btn-container {
      button {
        font-size: 1rem;
      }
    }
  }
}
