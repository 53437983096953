.teilnehmer-extra {
  background-color: #ffffff;
  border: 1px solid $border-color;
  border-radius: 12px;
  padding: 0.5rem 0.2rem 2rem 0.2rem;
  margin-bottom: 2rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
  hr {
    margin: 1rem 0rem;
  }
  p {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.5rem;
    max-width: 300px;
    margin: auto;
  }
  .one-checkbox-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem;
    span {
      font-size: 1.2rem;
      transform: translateY(2px);
    }
  }
}
