.responsive-table {
  min-height: 20vh;
  i {
    pointer-events: none;
  }
  table {
    width: 1100px;
    border-collapse: collapse;
    margin: 20px auto;
  }

  /* Zebra striping */
  tr:nth-of-type(odd) {
    background: #eee;
  }

  th {
    background: $primary-color;
    color: #fff;
    font-weight: 600;
  }

  td,
  th {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
    font-size: 0.9rem;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .not-payed {
    background-color: #fffce9 !important;
  }

  tr {
    cursor: pointer;
    transition: $transition;
    &:hover {
      color: $primary-color;
    }
  }
  .check-container {
    text-align: center;
  }
  @media (max-width: 1180.98px) {
    table {
      width: 100%;
    }
  }

  @media (max-width: 1199.98px) {
    .rp-col-lg {
      display: none !important;
    }
  }

  @media (max-width: 950.98px) {
    .rp-col-1 {
      display: none !important;
    }
  }

  @media (max-width: 767.98px) {
    td.rp-col-sm,
    th.rp-col-sm {
      display: none !important;
    }
  }

  @media (max-width: 600.98px) {
    td.rp-col-2,
    th.rp-col-2 {
      display: none !important;
    }
  }
  @media (max-width: 400.98px) {
    .rp-col-3 {
      display: none !important;
    }
  }
}

.pagination {
  width: 280px;
  margin: auto;
  text-align: center;
  border: 2px solid $border-color;
  border-radius: 4px;
  padding: 0.5rem;
  .anzahl {
    width: 180px;
    margin: auto;
    font-size: 1.4rem;
    font-weight: 600;
    text-align: center;
  }
  .page-count {
    font-size: 1.4rem;
    font-weight: 600;
  }
  .pages-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 180px;
    margin: auto;
    font-size: 1.2rem;
  }
  .btn-arrows {
    font-size: 1.8rem;
    font-weight: 600;
    cursor: pointer;
    transition: $transition;
    &:hover {
      color: $primary-color;
    }
  }
}
