.extra_handler_box {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 1rem;
  transform: translate(0%, -100%);
  transition: all 0.8s ease-in-out;
  cursor: pointer;
  z-index: 5;
  &.active {
    transform: translate(0%, 0%);
  }
  .content {
    width: 95%;
    max-width: 600px;
    margin: auto;
    min-width: 200px;
    font-size: 1.2rem;
    border: 2px solid $secondary-color;
    background-color: #fff;
    border-radius: 12px;
    padding: 1rem;
    border: 4px solid $primary-color;
    -webkit-box-shadow: 3px 3px 5px 6px #ccc;
    -moz-box-shadow: 3px 3px 5px 6px #ccc;
    box-shadow: 3px 3px 5px 6px #ccc;
    color: #333;
    cursor: default;
    transition: all 2s ease-out;
    .alarm {
      color: $third-color;
      font-weight: 600;
    }
  }

  .content-top-box {
    padding: 1rem;
    margin: 1rem auto;
    p {
      font-size: 1.1rem;
      padding-bottom: 0.6rem;
    }
    .round {
      margin-left: 0.3rem;
    }
    .name {
      font-weight: 600;
    }
  }
  .close {
    font-weight: 600;
    float: right;
    text-align: right;
    color: #333;
    cursor: pointer;
    &:hover {
      color: $primary-color;
    }
  }
}

@media (max-width: 450.98px) {
  #extra_handler_box {
    .content {
      font-size: 1rem;
    }
  }
}
