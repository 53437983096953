.website-bezahlung-novalnet {
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
  max-width: 1000px;
  .box-container {
    padding: 0rem 0.4rem 0rem 0.4rem;
  }
  @media (max-width: 1199.98px) {
    flex-direction: column;
  }

  @media (max-width: 767.98px) {
    .box-container {
      padding: 0rem 0rem 0rem 0.4rem;
    }
  }

  .first-row,
  .second-row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem;
    @media (max-width: 575.98px) {
      flex-direction: column;
      .box {
        padding-bottom: 1rem;
      }
    }
  }
  .box {
    width: 225px;
    $box-color: lighten($border-color, 10%);
    cursor: pointer;

    &:hover {
      .img-part {
        border-top: 2px solid $primary-color;
        border-left: 2px solid $primary-color;
        border-right: 2px solid $primary-color;
      }
      .title {
        border-bottom: 2px solid $primary-color;
        border-left: 2px solid $primary-color;
        border-right: 2px solid $primary-color;
      }
    }
    .title {
      border-bottom: 2px solid $box-color;
      border-left: 2px solid $box-color;
      border-right: 2px solid $box-color;
      background-color: $box-color;
      width: inherit;
      text-align: center;
      padding: 0.25rem 0rem;
      transition: $transition;
    }
    .img-part {
      border: 2px solid $box-color;
      width: inherit;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: $transition;
    }
    img.klarna {
      width: 110px;
      height: auto;
    }
    img.paypal {
      width: 120px;
      height: auto;
    }
    img.creditcard {
      width: 160px;
      height: auto;
    }
    img.sepa {
      width: 140px;
      height: auto;
    }
  }
}
