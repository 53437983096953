.user-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 6rem;
  .title {
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: 600;
    color: $primary-color;
  }
}

.user-form {
  background-color: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.2),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 2rem 0.5rem;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 550px;
  max-width: 100%;
  .input-container {
    width: 100%;
    margin-bottom: 2rem;
  }
  input {
    padding: 0.8rem 0.2rem;
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
    background-color: $light-color;
    border: none;
    border-bottom: 2px solid $gray-color;
    transition: $transition;
    &::placeholder {
      color: $gray-color;
    }
    &:focus {
      border-bottom: 2px solid $primary-color;
    }
  }

  .error-top {
    color: $red-color;
    align-self: flex-start;
    padding-bottom: 1.5rem;
    font-weight: 600;
    font-size: 1.1rem;
  }

  @media (max-width: 575.98px) {
    .user-form {
      width: 95%;
      margin: 0 auto;
    }
  }
}
