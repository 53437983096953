.website-admin {
  background-color: $secondary-color;
  padding-bottom: 4rem;
  min-height: 100vh;
  h2 {
    font-size: 1.9rem;
  }
  .error-text {
    color: $red-color;
    font-size: 1.2rem;
    font-weight: 600;
    transition: $transition;
    .hide {
      opacity: 0;
    }
  }
  .bezahlt-container {
    display: flex;
    p {
      font-size: 1.1rem;
    }
  }
  .subtext {
    font-size: 1.2rem;
  }
  .link {
    transition: $transition;
    cursor: pointer;
    &:hover {
      color: $primary-color;
    }
  }
}
