/* von Netflix Traversy-Media  und sass youtube kurse*/
// $primary-color: #42484e;
$primary-color: #c13929;
$secondary-color: #f8f9fa;
$third-color: #1a73e8;
$fourth-color: #2f6bcc;
$header-color-2: #fffce9;
$footer-color: #445b80;
$img-background: #1b3333;

$button-color: #c13929;
$border-color: lighten(#333, 60%);

$test-color: #e15609;

$dark-color: #0b1826;
$gray-color: #999;
$light-color: #f4f4f4;
$red-color: #ff0000;
$blue-color: #1a73e8;
$green-color: #28a745;
$orange-color: #ffa500;
$check-box-color: #c13929;

$max-width: 968px;

$primary-overlay: lighten($primary-color, 20%);

//Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
/* Roboto regular */

$transition: all 0.4s ease-out;
$font-script: 'Shadows Into Light', cursive;
$font-family: 'Roboto', Arial, Helvetica, sans-serif;
