.partner-bg-gray {
  background-color: $secondary-color;
  min-height: 100vh;
}
.partner-admin {
  .success-nachricht {
    text-align: center;
    font-size: 1.2rem;
  }
  .js_partner {
    color: $third-color;
  }
  .error-top {
    display: none;
    font-size: 1.2rem;
    font-weight: 600;
    &.error {
      display: block;
    }
  }

  .error-text {
    color: $red-color;
    font-size: 1.2rem;
    font-weight: 600;
    transition: $transition;
    .hide {
      opacity: 0;
    }
  }

  .one-partner {
    max-width: 500px;
    border: 2px solid $red-color;
    padding: 1rem;
    margin: 1rem auto;
    p {
      font-size: 1.1rem;
      padding-bottom: 0.6rem;
    }
    .round {
      margin-left: 0.3rem;
    }
    .name {
      font-weight: 600;
    }
  }
}

.partner-form {
  width: 100%;
  max-width: 500px;
  margin: auto;
  .input-container {
    width: 100%;
    transition: $transition;
    margin-bottom: 1rem;
    p {
      margin-bottom: 0.4rem;
      font-size: 1.1rem;
    }
    input {
      width: 100%;
    }
    .birthday-input {
      max-width: 120px;
    }
    small {
      display: none;

      color: $red-color;
      font-weight: 600;
    }
    &.error {
      input {
        border: 0.5px solid $red-color;
      }
      small {
        display: block;
      }
    }
  }
}
