.kurs-extra {
  background-color: $secondary-color;
  border: 1px solid $border-color;
  border-radius: 12px;
  padding: 0.5rem 0.2rem 2rem 0.2rem;
  margin-bottom: 2rem;

  hr {
    margin: 1rem 0rem;
  }
  p {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .statistik {
    font-size: 1.2rem;
  }
  .color-1 {
    color: $primary-color;
    font-weight: 600;
    margin-right: 12px;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.5rem;
    max-width: 300px;
    margin: auto;
  }
  .one-checkbox-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem;
    span {
      font-size: 1.2rem;
      transform: translateY(2px);
    }
  }
}
